<template>
  <div class="article event">
    <img :src="event.image" :alt="event.title" class="article-image">
    <div class="article-details">
      <div class="article-author">
        <DateFormat :date="event.date" />
      </div>
      <h4>{{ event.title }}</h4>
      <div class="article-excerpt">{{getDescription(event.description)}}</div>
      <router-link :to="`/events/${event.seo.slug}`" class="btn medium red ghost">
        Read more
        <Icon type="fas" icon="arrow-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import DateFormat from "./DateFormat.vue";

export default {
  name: "EventItem",
  components: {
    Icon,
    DateFormat,
  },
  props: {
    event: {
      type: Object,
    },
  },
  methods: {
    getDescription(content) {
      const div = document.createElement('div');
      div.innerHTML = content;
      const p = div.querySelector('p');
      if (!p) {
        return '';
      }
      return p.innerText.length > 200 ? p.innerText.substr(0, 197) + '...' : p.innerText;
    },
  },
};
</script>

<style scoped>
.article {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  transition: all .2s ease-out;
}

#app[no-touch] .article:hover {
  box-shadow: 0 24px 32px rgba(52, 52, 52, 0.12);
  transform: translateY(-8px);
}

.article .article-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 32px;
}

.article h4 {
  font-size: 32px;
  margin-top: 16px;
}

.article .article-categories {
  font-size: 16px;
  letter-spacing: .1em;
}

.article .article-author .date-format {
  font: 900 22px/1.2 'Metropolis';
  color: #DB735C;
}

.article .article-excerpt {
  margin: 16px 0 32px;
  flex: 1;
}

.article .article-image {
  display: block;
  width: 100%;
  height: 280px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
}

@media screen and (max-width: 1280px) {
  .article .article-image {
    height: 300px;
  }
}

@media screen and (max-width: 720px) {
  .article h4 {
    font-size: 32px;
  }
  .article .article-image {
    height: 260px;
  }
  .article .article-details {
    margin-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .article h4 {
    font-size: 24px;
  }
  .article .article-categories {
    font-size: 12px;
  }
  .article .article-details {
    padding: 0;
  }
}
</style>
