<template>
  <div
    class="page homepage"
    :newsletter-opened="newsletterOpened"
    :loaded="
      Boolean(incubators) &&
      Boolean(settings) &&
      Boolean(posts) &&
      Boolean(home) &&
      Boolean(wwd)
    "
  >
    <section class="top-section" v-if="home">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <h3>{{ home.headline }}</h3>
            <h5>{{ home.subtitle }}</h5>
            <p>{{ home.description }}</p>
            <div class="buttons">
              <router-link class="btn" to="/what-we-do">What we do</router-link>
              <router-link class="btn ghost" to="/case-studies">Our clients</router-link>
            </div>
          </div>
          <div class="slider-container" v-if="home.slides">
            <img
              v-for="(slide, i) in home.slides"
              :key="slide"
              :src="slide"
              :active="i === activeTopIndex"
              alt=""
              class="slide"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="metrics" v-if="home && home.metrics">
      <div class="wrapper">
        <div class="section-title" v-if="home.metricsText">
          <h3>{{home.metricsText.title}}</h3>
          <p>{{home.metricsText.description}}</p>
        </div>
        <div class="container">
          <div class="block" v-for="(metric, i) in home.metrics" :key="`metric-${i}`">
            <b>{{metric.value}}</b>
            {{metric.label}}
          </div>
        </div>
      </div>
    </section>

    <section class="what-we-do" v-if="home">
      <div class="wrapper">
        <div class="container">
          <div class="image-container">
            <div class="image">
              <img :src="home.wwd.image" alt="" />
            </div>
          </div>
          <div class="content">
            <h3 v-html="home.wwd.title"></h3>
            <h5>{{ home.wwd.subtitle }}</h5>
            <p>{{ home.wwd.description }}</p>
            <div class="buttons">
              <router-link class="btn" to="/what-we-do">What we do</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="news" v-if="posts && posts.length">
      <div class="wrapper">
        <div class="section-title">
          <h3>In The News</h3>
          <h5>All our latest blog articles</h5>
          <p>WestBIC supports an extensive range of highly innovative startups with the objective of bringing new ideas, jobs and wealth to our region.</p>
          <div class="section-title">
            <router-link class="btn" to="/news">All our articles</router-link>
          </div>
        </div>
        <swiper
          ref="slider"
          :options="{
            slidesPerView: 1.1,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            spaceBetween: 24,
            loop: false,
            grabCursor: true,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            breakpoints: {
              1440: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 2.2,
              },
            },
          }"
        >
          <swiper-slide v-for="post in posts.slice(0, 5)" :key="post.id">
            <div class="post-container">
              <NewsItem :post="post" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

    <section class="case-studies" v-if="caseStudies && featuredCase">
      <div class="wrapper">
        <div class="section-title">
          <h3>Our Clients</h3>
          <h5>Companies We have Supported</h5>
          <p>{{caseStudies.description}}</p>
          <router-link class="btn" to="/case-studies">Explore all our clients</router-link>
        </div>
        <FeaturedCase :featuredCase="featuredCase" />
      </div>
    </section>

    <section class="events">
      <div class="section-title">
        <h3>Events</h3>
        <h5>Find all our events</h5>
        <p>WestBIC supports an extensive range of highly innovative startups with the objective of bringing new ideas, jobs and wealth to our region.</p>
        <router-link class="btn red" to="/events">Discover all events</router-link>
      </div>
      <div class="wrapper">
        <div class="event-container" v-for="event in events" :key="event.id">
          <EventItem :event="event" />
        </div>
      </div>
    </section>

    <section class="what-we-do network" v-if="home && home.network">
      <div class="wrapper">
        <div class="container">
          <div class="image-container">
            <div class="image">
              <img :src="home.network.image" alt="" />
            </div>
          </div>
          <div class="content">
            <h3 v-html="home.network.title"></h3>
            <h5>{{home.network.subtitle}}</h5>
            <p>{{home.network.description}}</p>
            <div class="buttons">
              <router-link class="btn" to="/bic-network">BIC Network</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="incubation" v-if="incubators && incubators.length">
      <div class="section-title">
        <h3>Incubation</h3>
        <h5>Incubation spaces available in the West and Northwest of Ireland for startups and entrepreneurs for remote working, hotdesking or collaboration spaces.</h5>
        <router-link class="btn" to="/incubation">Discover our incubators</router-link>
      </div>
      <div class="wrapper">
        <div class="container">
          <div class="incubator" v-for="incubator in incubators" :key="incubator.id">
            <router-link :to="`/incubation/${incubator.seo.slug}`">
              <img :src="incubator.image" :alt="incubator.name" class="incubator-image">
            </router-link>
            <h5 :style="{ color: incubator.color }">{{incubator.name}}</h5>
            <p>{{incubator.excerpt}}</p>
            <router-link class="btn medium ghost" :style="{ color: incubator.color }" :to="`/incubation/${incubator.seo.slug}`">
              <span>Read more</span>
              <Icon type="fas" icon="arrow-right" />
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="podcast" v-if="podcast">
      <div class="wrapper">
        <div class="container">
          <img :src="podcast.image" :alt="podcast.title" class="podcast-cover">
          <div class="podcast-details">
            <h3>{{podcast.title}}</h3>
            <p>{{podcast.description}}</p>
            <div class="podcast-sources">
              <a v-if="podcast.platforms.spotify" :href="podcast.platforms.spotify" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/spotify.svg" alt="Listen on Spotify">
              </a>
              <a v-if="podcast.platforms.itunes" :href="podcast.platforms.itunes" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/itunes.svg" alt="Listen on iTunes">
              </a>
              <a v-if="podcast.platforms.soundcloud" :href="podcast.platforms.soundcloud" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/soundcloud.svg" alt="Listen on Soundcloud">
              </a>
              <a v-if="podcast.platforms.youtube" :href="podcast.platforms.youtube" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/images/youtube.svg" alt="Listen on YouTube">
              </a>
            </div>
            <div class="share" v-if="podcast.podcast">
              <h5>Share {{podcast.podcast.title}}</h5>
              <div class="socials">
                <a :href="`mailto:?subject=${podcast.title}&body=${podcastLink}`" class="social" target="_blank">
                  <Icon icon="envelope" type="far" size="small" rounded />
                </a>
                <a :href="`https://www.facebook.com/sharer/sharer.php?u=${podcastLink}`" class="social" target="_blank">
                  <Icon icon="facebook-f" size="small" rounded />
                </a>
                <a :href="`https://twitter.com/home?status=${podcast.title} ${podcastLink}`" class="social" target="_blank">
                  <Icon icon="twitter" size="small" rounded />
                </a>
                <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${podcastLink}&title=${podcast.title}`" class="social" target="_blank">
                  <Icon icon="linkedin-in" size="small" rounded />
                </a>
                <a ref="podcastLink" :active="copied" :data-clipboard-text="podcastLink" class="social">
                  <Icon icon="link" type="fas" size="small" rounded />
                  <div class="valid">Copied!</div>
                </a>
              </div>
              <div class="podcast-about">
                <h5>About</h5>
                <p>{{podcast.podcast.title}}</p>
              </div>
            </div>
            <router-link class="btn sea" to="/podcast">Listen to all episodes</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewsItem from "./NewsItem.vue";
import EventItem from "./EventItem.vue";
import FeaturedCase from "./FeaturedCase.vue";
import DateFormat from "./DateFormat.vue";
import BpsImage from "./BpsImage.vue";
import Icon from "./Icon.vue";
import Clipboard from 'clipboard';

export default {
  name: "Home",
  components: {
    NewsItem,
    EventItem,
    FeaturedCase,
    DateFormat,
    BpsImage,
    Icon,
  },
  data() {
    return {
      selectedIndex: 0,
      activeIndex: 0,
      activeTopIndex: 0,
      pastEvents: [],
      nextEvents: [],
      incubators: null,
      slides: null,
      podcast: null,
      caseStudies: null,
      currentTime: Date.now(),
      home: null,
      featuredCase: null,
      wwd: null,
      loading: false,
      selectedVideo: null,
      newsletterEmail: "",
      newsletterOpened: false,
      newsletterLoading: false,
      newsletterSuccess: false,
      newsletterError: false,
      copied: false,
    };
  },
  computed: {
    ...mapGetters({
      posts: "getPosts",
      settings: "getSettings",
      isMobile: "getMobile",
      functions: "getFunctions",
      testMode: "getTest",
    }),
    podcastLink() {
      return `https://${window.location.host}/podcast`;
    },
    selectedPost() {
      return Array.isArray(this.posts) ? this.posts[this.selectedIndex] : null;
    },
    selectedImage() {
      if (!this.selectedPost) return null;
      return this.getImage(this.selectedPost["content:encoded"]);
    },
    screenWidth() {
      return window.innerWidth;
    },
    db() {
      return this.$store.state.firebase.firestore;
    },
    tweets() {
      return (
        (this.settings &&
          this.settings.latest_tweets &&
          this.settings.latest_tweets.tweets) ||
        []
      );
    },
    events() {
      return [
        ...this.nextEvents,
        ...this.pastEvents,
      ].slice(0, 3);
    },
  },
  watch: {
    testMode() {
      this.getItems();
    },
    // activeIndex() {
    //   this.playVideo();
    // },
    // selectedVideo() {
    //   this.playVideo();
    // },
  },
  methods: {
    // playVideo() {
    //   const src = this.slides[this.activeIndex].video;
    //   const videos =
    //     this.$refs.slider && this.$refs.slider.$el
    //       ? this.$refs.slider.$el.querySelectorAll("video")
    //       : [];
    //   videos.forEach((video) =>
    //     !this.selectedVideo && video.src === src ? video.play() : video.pause()
    //   );
    // },
    getDateFormat(date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    },
    subscribeNewsletter() {
      if (this.newsletterLoading) return false;
      this.newsletterError = this.newsletterSuccess = false;
      this.newsletterLoading = true;
      this.functions
        .httpsCallable("subscribeNewsletter")({
          email: this.newsletterEmail,
          test: this.testMode,
        })
        .then(() => {
          this.newsletterEmail = "";
          this.newsletterLoading = false;
          this.newsletterSuccess = true;
        })
        .catch(() => {
          this.newsletterLoading = false;
          this.newsletterError = true;
        });
    },
    getImage(content) {
      const div = document.createElement("div");
      div.innerHTML = content;
      const img = div.querySelector("img");
      // div.remove()
      if (img) return img.src;
      return null;
    },
    getEvents() {
      if (this.pastEventsRef) this.pastEventsRef();
      if (this.nextEventsRef) this.nextEventsRef();
      this.pastEventsRef = this.db()
        .collection(`${this.testMode ? 'test_' : ''}events`)
        .where('start', '<=', Date.now())
        .orderBy('start', 'desc')
        .limit(3)
        .onSnapshot(events => {
          this.pastEvents = events.docs.map(event => event.data());
        });
      this.nextEventsRef = this.db()
        .collection(`${this.testMode ? 'test_' : ''}events`)
        .where('start', '>', Date.now())
        .orderBy('start', 'asc')
        .limit(3)
        .onSnapshot(events => {
          this.nextEvents = events.docs.map(event => event.data());
        });
    },
    getItems() {
      if (this.homeRef) this.homeRef();
      if (this.wwdRef) this.wwdRef();
      if (this.caseStudiesRef) this.caseStudiesRef();
      if (this.podcastRef) this.podcastRef();
      if (this.casesRef) this.casesRef();
      if (this.incubationRef) this.incubationRef();
      // if (this.slidesRef) this.slidesRef();
      if (this.eventRef) this.eventRef();
      clearInterval(this.topSliderInt);
      this.homeRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}settings/homepage`)
        .onSnapshot((settings) => {
          const isInit = !this.home;
          this.home = settings.data();
          if (isInit && this.home.slides) {
            this.$nextTick().then(() => {
              const swiper = this.$refs.slider.$el;
              swiper.addEventListener('mousewheel', function(e) {
                if (Math.abs(e.deltaX) > 0 || swiper.swiping) {
                  // swiper.swiping = true;
                  e.preventDefault();
                  clearTimeout(this.slideTO);
                  const translate = swiper.swiper.getTranslate() - e.deltaX;
                  swiper.swiper.translateTo(translate, 0);
                  this.slideTO = setTimeout(function() {
                    let finalTranslate = 0;
                    let slideIndex = 0;
                    for (let i = 0; i < swiper.swiper.slides.length; i++) {
                      const size = swiper.swiper.slides[i].swiperSlideSize;
                      const _translate = -swiper.swiper.getTranslate();
                      let updatedTranslate = finalTranslate + size;
                      if (updatedTranslate >= _translate) {
                        if (updatedTranslate - _translate < size * (e.deltaX < 0 ? 0.3 : 0.7)) {
                          finalTranslate = updatedTranslate;
                          slideIndex = i + 1;
                        }
                        break;
                      }
                      finalTranslate = updatedTranslate;
                      slideIndex = i + 1;
                    }
                    swiper.swiper.slideTo(slideIndex, 500);
                    // swiper.swiping = false;
                  }, 100);
                  return false;
                }
              });
            });
            this.topSliderInt = setInterval(() => {
              const index = this.activeTopIndex + 1;
              this.activeTopIndex = index < this.home.slides.length ? index : 0;
            }, 5000);
          }
        });
      this.casesRef = this.db()
        .collection(`${this.testMode ? 'test_' : ''}cases`)
        .where('featured', '==', true)
        .limit(1)
        .onSnapshot(cases => {
          this.featuredCase = cases.size ? cases.docs[0].data() : null;
        })
      this.wwdRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}pages/whatwedo`)
        .onSnapshot((settings) => {
          this.wwd = settings.data();
        });
      this.caseStudiesRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}pages/casestudies`)
        .onSnapshot((settings) => {
          this.caseStudies = settings.data();
        });
      this.podcastRef = this.db()
        .doc(`${this.testMode ? "test_" : ""}pages/podcast`)
        .onSnapshot((settings) => {
          this.podcast = settings.data();
          if(this.podcast && this.podcast.podcast) {
            this.$nextTick().then(() => {
              const clip = new Clipboard(this.$refs.podcastLink);
              clip.on('success', () => {
                clearTimeout(this.copiedTO);
                this.copied = true;
                this.copiedTO = setTimeout(() => this.copied = false, 1500);
              })
            })
          }
        });
      this.incubationRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}incubations`)
        .orderBy("order")
        .limit(2)
        .onSnapshot((incubators) => {
          this.incubators = incubators.docs.map((incubator) => incubator.data());
        });
      // this.slidesRef = this.db()
      //   .collection(`${this.testMode ? "test_" : ""}slides`)
      //   .orderBy("order")
      //   .onSnapshot((slides) => {
      //     this.slides = slides.docs.map((slide) => slide.data());
      //   });
      this.getEvents();
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.homeRef) this.homeRef();
    if (this.wwdRef) this.wwdRef();
    if (this.caseStudiesRef) this.caseStudiesRef();
    if (this.podcastRef) this.podcastRef();
    if (this.casesRef) this.casesRef();
    // if (this.slidesRef) this.slidesRef();
    if (this.incubationRef) this.incubationRef();
    if (this.pastEventsRef) this.pastEventsRef();
    if (this.nextEventsRef) this.nextEventsRef();
    clearInterval(this.topSliderInt);
  },
};
</script>

<style scoped>
.top-section {
  margin: 0 auto 96px;
}

.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(28, 172, 214, 0.08) url(../assets/images/wb_home_tree.svg) no-repeat 33% bottom/auto 104%;
  border-radius: 16px;
  min-height: 760px;
  padding: 48px 0;
  margin-right: 144px;
}

.top-section .container .content {
  position: relative;
  width: calc(50% + 72px);
  padding: 0 42px 0 128px;
  min-width: 420px;
  z-index: 2;
}

.top-section .container .content h3 {
  max-width: 348px;
}

.top-section .container .content h5 {
  margin: 16px 0 32px;
  color: #656662;
}

.top-section .container .content p {
  font-size: 18px;
}

.top-section .content .buttons {
  margin-top: 64px;
}

.top-section .container .slider-container {
  position: absolute;
  top: 24px;
  right: -144px;
  width: calc(50% + 72px);
  bottom: 24px;
  border-radius: 16px;
  background-color: #fafafa;
  overflow: hidden;
}

.top-section .container .slider-container .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.top-section .container .slider-container .slide[active] {
  opacity: 1;
}

.what-we-do {
  background: #fafafa;
  padding: 64px 0;
  margin-bottom: 64px;
}

.what-we-do .container {
  display: flex;
  align-items: center;
}

.what-we-do .container .content {
  width: 720px;
  margin-left: 96px;
  flex-shrink: 0;
}

.what-we-do .container .content h3:before {
  content: "";
  display: block;
  width: 110px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-bottom: 24px;
}

.what-we-do .container .content h5 {
  color: #656662;
  margin: 24px 0 32px;
  line-height: 1.2;
  font-size: 22px;
}

.what-we-do .container .content p {
  font-size: 18px;
}

.what-we-do .container .content .buttons {
  margin-top: 42px;
}

.what-we-do .image-container {
  flex-grow: 1;
}

.what-we-do .image {
  position: relative;
  padding-top: 100%;
}

.what-we-do .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.metrics {
  margin-bottom: 96px;
}

.metrics .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.metrics .section-title {
  margin-bottom: 48px;
}

.metrics .section-title h3 {
  margin-bottom: 16px;
}

.metrics .block {
  width: calc(33.333333% - 24px);
  padding: 64px 24px;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
  border-radius: 16px;
  background-color: rgba(29, 171, 214, 0.08);
  line-height: 1;
  font-family: Metropolis;
  margin: 0 12px;
}

.metrics .block b {
  display: block;
  font-weight: 900;
  font-size: 72px;
  margin-bottom: 8px;
}

.news {
  position: relative;
  margin-bottom: 96px;
  overflow: hidden;
}

.news:before,
.news:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.72);
  background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  width: calc(calc(100% - 1920px) / 2);
  z-index: 2;
  pointer-events: none;
}

.news:after {
  left: auto;
  right: 0;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.news h3 {
  text-align: center;
}

.news h3:after {
  margin-left: auto;
  margin-right: auto;
}

.news .post-container {
  height: 100%;
}

.news .swiper-slide {
  height: auto;
}

.news .swiper-container {
  overflow: initial;
}

.case-studies {
  position: relative;
  /* background: url(../assets/images/wb_bg_case-studies.png) no-repeat top center/100% auto; */
  margin-bottom: 96px;
}

.case-studies .wrapper {
  position: relative;
  padding-top: 42px;
}

.case-studies .wrapper:before {
  content: '';
  position: absolute;
  width: 7px;
  height: 132px;
  background: url(../assets/images/wb_o_clients-line.svg) no-repeat top center/contain;
  right: 168px;
  top: 0;
}

section .section-title {
  text-align: center;
  width: 687px;
  max-width: 100%;
  margin: 0 auto 64px;
}

.case-studies .section-title h3:before {
  content: '';
  display: block;
  width: 165px;
  height: 96px;
  background: url(../assets/images/wb_o_case-studies.svg) no-repeat center/contain;
  margin: 0 auto 12px;
}

section .section-title h5 {
  margin: 16px 0 24px;
  line-height: 1.2;
  color: #656662;
}

section .section-title .btn {
  margin-top: 42px;
}

.events {
  margin-bottom: 96px;
}

.events .wrapper {
  display: flex;
}

.events .event-container {
  width: 33.3333333%;
  padding: 0 8px;
}

.network {
  margin-bottom: 32px;
}

.network .container {
  position: relative;
}

.network .container:before {
  content: '';
  position: absolute;
  top: -48px;
  right: 0;
  width: 292px;
  height: 308px;
  background: url(../assets/images/wb_o_initiatives.svg) no-repeat center/contain;
}

.incubation {
  margin-bottom: 96px;
  background: url(../assets/images/wb_o_incubators.svg) no-repeat center 0/auto;
  padding-top: 96px;
}

.incubation .section-title .btn {
  margin-top: 18px;
}

.incubation .container {
  display: flex;
}

.incubation .incubator {
  width: 50%;
  padding: 0 24px;
}

.incubation .incubator .incubator-image {
  display: block;
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 16px;
  margin-bottom: 32px;
}

.incubation .incubator p {
  margin: 24px 0 32px;
  min-height: 104px;
}

#app[no-touch] .incubation .incubator .btn.ghost:hover {
  background-color: currentColor;
}

#app[no-touch] .incubation .incubator .btn.ghost:hover span,
#app[no-touch] .incubation .incubator .btn.ghost:hover .icon {
  color: #fff;
}

.podcast {
  padding: 64px 0;
  background: #fafafa;
}

.podcast .container {
  display: flex;
}

.podcast .podcast-details {
  position: relative;
  margin: 0 0 0 80px;
}

.podcast .container .podcast-cover {
  width: 560px;
  height: 560px;
  object-fit: cover;
  border-radius: 16px;
}

.podcast .podcast-details:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 36px;
  width: 8px;
  height: 170px;
  background: url(../assets/images/wb_o_separator.svg) no-repeat center/contain;
}

.podcast .podcast-details h3 {
  margin-bottom: 16px;
  color: #0A7E8C;
}

.podcast .share > h5 {
  color: #0A7E8C;
  margin-bottom: 24px;
}

.podcast .podcast-sources {
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 40px;
}

.podcast .podcast-sources a {
  display: block;
  margin-right: 24px;
  margin-bottom: 12px;
}

.podcast .podcast-sources a img {
  height: 40px;
}

.podcast .socials {
  display: flex;
}

.podcast .socials .social {
  margin-right: 8px;
}

.podcast .podcast-about h5 {
  margin: 40px 0 16px;
}

.podcast .podcast-about p {
  font-size: 18px;
}

.podcast .podcast-details .btn {
  margin-top: 40px;
}

@media screen and (max-width: 1380px) {
  .metrics .block b {
    font-size: 56px;
  }
}

@media screen and (max-width: 1280px) {
  .events .event-container {
    width: 50%;
  }
  .events .event-container:last-child {
    display: none;
  }
  .podcast .container .podcast-cover {
    width: 480px;
    height: 480px;
  }
  .what-we-do .container .content {
    margin-left: 48px;
    max-width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .events .event-container {
    padding: 0 8px;
  }
  .case-studies {
    background-size: auto 480px;
  }
  .metrics .block {
    padding: 48px 24px;
    font-size: 18px;
  }
  .metrics .block b {
    font-size: 48px;
  }
  .top-section {
    max-width: none;
    margin-bottom: 64px;
  }
  .metrics {
    margin-bottom: 64px;
  }
  .top-section .container .slider-container {
    position: relative;
    height: 360px;
    right: 0;
    width: calc(100% - 96px);
    top: 0;
    margin: 0 48px 48px;
  }
  .top-section .container {
    min-height: auto;
    margin-right: 0;
    flex-direction: column-reverse;
  }
  .top-section .container .content {
    width: auto;
    min-width: 0;
    padding: 0 48px;
  }
  .podcast {
    padding: 64px 0;
  }
  .podcast .container {
    flex-direction: column;
  }
  .podcast .podcast-details {
    margin: 32px 0 0 96px;
  }
  .podcast .container .podcast-cover {
    margin-left: 96px;
  }
  .what-we-do {
    padding: 48px 0;
  }
  .what-we-do .container {
    flex-direction: column;
    align-items: flex-start;
  }
  .what-we-do .image-container {
    width: 100%;
  }
  .what-we-do .image {
    padding-top: 35%;
    margin-bottom: 48px;
    min-height: 180px;
  }
  .what-we-do .container .content {
    width: auto;
    margin-left: 0;
    max-width: none;
    padding: 0 24px;
  }
  .what-we-do .container .content h3:after,
  .news h3:after {
    display: none;
  }
}

@media screen and (max-width: 880px) {
  .news {
    margin-bottom: 64px;
  }
  .incubation .incubator {
    padding: 0 12px;
  }
  .incubation .incubator .incubator-image {
    height: 260px;
  }
}

@media screen and (max-width: 767px) {
  .what-we-do .container .content .buttons {
    margin-top: 48px;
  }
  section .section-title {
    padding: 0 24px;
  }
  .events {
    margin-bottom: 64px;
  }
  .events .event-container {
    width: auto;
    padding: 8px 0;
  }
  .events .wrapper {
    flex-direction: column;
  }
  .news .article >>> .article-excerpt {
    font-size: 0px;
    margin: 16px 0;
  }
  .metrics .container {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }
  .metrics .block {
    width: 420px;
    max-width: 100%;
    margin: 12px 0;
  }
}

@media screen and (max-width: 680px) {
  .incubation .container {
    flex-direction: column;
  }
  .incubation .incubator {
    width: auto;
    padding: 16px 0;
  }
  .podcast .podcast-details {
    margin: 32px 0 0;
  }
  .podcast .container .podcast-cover {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    height: auto;
  }
  .podcast .podcast-sources a {
    margin-right: 8px;
  }
  .podcast .podcast-sources a:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .top-section .container {
    padding: 24px 0 48px;
  }
  .top-section .container .content {
    padding: 0 24px;
  }
  .top-section .container .slider-container {
    width: calc(100% - 48px);
    margin: 0 24px 24px;
  }
  .metrics .section-title {
    margin-bottom: 24px;
  }
}
</style>
